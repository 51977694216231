import React from "react";
import { Link } from "react-router-dom";

const Blog = React.memo(({ blog }) => {
  function truncateText(text, wordLimit) {
    const words = text.split(" ");
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return (
    <Link
      to={`${blog.path}/${blog.id}`}
      className="rounded-lg overflow-hidden h-full flex flex-col border border-borderLight_color"
    >
      <div className="flex flex-col flex-grow">
        <img
          className="h-40 w-full object-cover"
          alt="example"
          src={blog.image}
          loading="lazy"
          role="presentation"
          decoding="async"
          fetchPriority="high"
        />
        <div className="flex-1 p-2">
          <b className="title">{blog.title}</b>
        </div>
        <p className="text-sm w-full text-textGray p-2">
          {truncateText(blog.desc, 40)}{" "}
          <Link
            className="underline text-highLight_color"
            to={`${blog.path}/${blog.id}`}
          >
            More
          </Link>
        </p>
      </div>
    </Link>
  );
});

export default Blog;
