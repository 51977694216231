import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

const CommentsSection = React.memo(({ data }) => {
  const { t } = useTranslation();
  // const [circles, setCircles] = useState([]);

  // useEffect(() => {
  //   const generateCircles = () => {
  //     const circleArray = Array.from({ length: 8 }, () => ({
  //       top: Math.random() * 100 + "%",
  //       left: Math.random() * 100 + "%",
  //     }));
  //     setCircles(circleArray);
  //   };

  //   generateCircles();
  // }, []);

  return (
    <div className="relative bg-bg_lightGray w-full h-[80vh] flex justify-center items-center overflow-hidden my-8 rounded-xl">
      <svg
        className="absolute top-0 w-full h-full z-10"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          d="M-50,0 C400,-250 800,700 1500,150"
          stroke="#453CFF"
          strokeWidth="50"
          fill="none"
        />
      </svg>

      {data.map((circle, index) => (
        <div
          key={index}
          className="absolute z-10 w-fit hover:bg-[#c5c2ff] hover:bg-opacity-50 rounded-full"
          style={{
            top: circle.top,
            left: circle.left,
          }}
        >
          <Tooltip title={circle.content} overlayClassName="z-20">
            <div className="p-2 rounded-full border-2 border-transparent hover:border-double-border relative transition-all duration-300">
              <Avatar
                src={circle.image}
                size={circle.size}
                icon={<UserOutlined />}
              />
            </div>
          </Tooltip>
        </div>
      ))}

      <div className="relative z-10 text-center">
        <h3 className="text-2xl md:text-4xl font-bold uppercase">
          {t("titles.testimonials")}
        </h3>
        <p className="text-lg md:text-2xl mt-4">
          {t("titles.testimonialsTitle")}
        </p>
      </div>
    </div>
  );
});

export default CommentsSection;
