import React from "react";
import RestartLogo from "../../utils/icons/RestartLogo";
import { Button, Input } from "antd";
import { useNavbarItems } from "../../utils/constants";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Footer = React.memo(() => {
  const location = useLocation();
  const currentPath = location.pathname;

  const { isArabic } = useSelector((state) => ({
    isArabic: state.shared.isArabic,
  }));

  const navbarItem = useNavbarItems();
  const { t } = useTranslation();

  return (
    <footer className="w-full">
      <div className="container flex flex-col gap-4 py-8">
        <div className="flex flex-col md:flex-row gap-4 md:items-center items-start py-4 justify-between border-b border-borderLight_color">
          <div className="flex flex-col gap-2">
            <RestartLogo />
            <ul
              className={`flex items-center flex-wrap gap-4 md:gap-8 ${
                isArabic ? "flex-row-reverse" : "flex-row"
              }`}
              dir={isArabic ? "ltr" : ""}
            >
              {navbarItem.map((item) => (
                <li key={item.id}>
                  <Link
                    to={item.path}
                    className={`text-sm md:text-base ${
                      currentPath === item.path ? "font-bold" : ""
                    }`}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <b className="text-sm">{t('titles.send')}</b>
            <div className="flex items-center gap-4">
              <Input placeholder={t('placeHolder.email')} />
              <Button type="primary">{t('titles.contact')}</Button>
            </div>
          </div>
        </div>

        <div className="flex justify-between flex-col sm:flex-row gap-4 w-full items-start md:items-center">
          <small className="text-textGray">
            © 2077 Untitled UI. All rights reserved.
          </small>
          <div className="flex items-center gap-4">
            <small className="text-textGray cursor-pointer">Terms</small>
            <small className="text-textGray cursor-pointer">Privacy</small>
            <small className="text-textGray cursor-pointer">Cookies</small>
          </div>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
