import { useTranslation } from "react-i18next";
import {
  Privacy,
  SincereIntention,
  HighAccuracy,
  Transparency,
} from "../utils/icons/icons";
import { Link } from "react-router-dom";

import trainingImage2 from "./../assets/images/WhatsApp Image 2024-11-04 at 12.12.01_0a786a37.webp";
import trainingImage7 from "./../assets/images/Life skills training with a focus on personal development through daily varied activities (1).webp";
import trainingImage8 from "./../assets/images/Life skills training with a focus on personal development through daily varied activities.webp";
import trainingImage9 from "./../assets/images/Life skills training with a focus on personal development through daily varied activities. (1).webp";


import supervisionImage1 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.42.02_841ea5a3.webp";
import supervisionImage2 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.42.03_bf838ce4.webp";
import supervisionImage3 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.42.03_076de179.webp";
import supervisionImage4 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.42.34_d76e9486.webp";
import supervisionImage5 from "./../assets/images/Supervision by specialists in various fields applying the latest treatment methods, fluent in Arabic and of Arab origin.webp";
import supervisionImage6 from "./../assets/images/Supervision by specialists in various fields applying the latest treatment methods, fluent in Arabic and of Arab origin. (1).webp";
import supervisionImage7 from "./../assets/images/Supervision by specialists in various fields applying the latest treatment methods, fluent in Arabic and of Arab origin. (2).webp";

import medicalCareImage3 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.23.44_c0e251c2.webp";
import medicalCareImage4 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.23.44_6e759373.webp";
import medicalCareImage5 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.23.45_9abd49cb.webp";
import medicalCareImage6 from "./../assets/images/Intensive medical care from specialists, including all necessary laboratory tests and continuous medical supervision. (2).webp";
import medicalCareImage7 from "./../assets/images/Intensive medical care from specialists, including all necessary laboratory tests and continuous medical supervision.webp";
import medicalCareImage8 from "./../assets/images/Intensive medical care from specialists, including all necessary laboratory tests and continuous medical supervision. (1).webp";


import lifeCoachImage4 from "./../assets/images/Life coach available throughout the recovery period for life skills training. (2).webp";
import lifeCoachImage5 from "./../assets/images/Life coach available throughout the recovery period for life skills training. (1).webp";
import lifeCoachImage6 from "./../assets/images/Life coach available throughout the recovery period for life skills training.webp";

import tripsImage4 from "./../assets/images/WhatsApp Image 2024-11-08 at 12.39.56_44e6a505.webp";
import tripsImage5 from "./../assets/images/Touristic trips in different cities in Turkey to explore every weekend. (2).webp";
import tripsImage6 from "./../assets/images/Touristic trips in different cities in Turkey to explore every weekend.webp";
import tripsImage7 from "./../assets/images/Touristic trips in different cities in Turkey to explore every weekend. (1).webp";

import detoxImage5 from "./../assets/images/detox in home page.webp";
import detoxImage6 from "./../assets/images/Detoxification and control of withdrawal symptoms in the best facilities in Istanbul, with the possibility of hospital work outside. (2).webp";
import detoxImage7 from "./../assets/images/Detoxification and control of withdrawal symptoms in the best facilities in Istanbul, with the possibility of hospital work outside. (1).webp";
import detoxImage8 from "./../assets/images/Detoxification and control of withdrawal symptoms in the best facilities in Istanbul, with the possibility of hospital work outside.webp";

import accommodationImage1 from "./../assets/images/WhatsApp Image 2024-11-07 at 21.45.13_c281d24c.webp";
import accommodationImage2 from "./../assets/images/WhatsApp Image 2024-11-07 at 21.45.14_efd9b1b7.webp";
import accommodationImage3 from "./../assets/images/WhatsApp Image 2024-11-07 at 21.45.14_df512dd2.webp";
import accommodationImage4 from "./../assets/images/WhatsApp Image 2024-11-07 at 21.45.14_42caec02.webp";
import accommodationImage5 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.56.11_8b1770f0.webp";
import accommodationImage6 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.56.11_44c72cac.webp";

import saadImage from "./../assets/images/5330335846819293793.webp";
import hozifaImage from "./../assets/images/5330335846819293789.webp";
import usamaImage from "./../assets/images/5330335846819293787.webp";
import younisImage from "./../assets/images/5330335846819293797.webp";
import ammarImage from "./../assets/images/WhatsApp Image 2024-11-03 at 23.00.03_64f500c2.webp";
import nashatImage from "./../assets/images/WhatsApp Image 2024-11-03 at 20.21.19_cb1e3271.webp";
import samiImage from "./../assets/images/WhatsApp Image 2024-11-07 at 16.07.12_27a0c0dd.webp";
import daniaImage from "./../assets/images/WhatsApp Image 2024-11-07 at 16.06.18_d00354c7.webp";
import giorgosImage from "./../assets/images/WhatsApp Image 2024-11-03 at 22.16.28_4866b7f7.webp";
import heshamImage from "./../assets/images/WhatsApp Image 2024-11-03 at 20.21.06_55ec33cb.webp";
import ahlamImage from "./../assets/images/5330335846819293799.webp";
import amrImage from "./../assets/images/WhatsApp Image 2024-11-03 at 20.17.58_26fd8112.webp";
import abdelrazzaqImage from "./../assets/images/5330335846819293800.webp";
import tahaImage from "./../assets/images/5330335846819293795.webp";
import issamImage from "./../assets/images/5330335846819293792.webp";
import rahmaImage from "./../assets/images/5330335846819293783.webp";
import doukiImage from "./../assets/images/WhatsApp Image 2024-11-09 at 16.27.21_98f5cc95.webp";
import tarekImage from "./../assets/images/WhatsApp Image 2024-11-09 at 16.28.19_45b92c36.webp";
import maramImage from "./../assets/images/5330335846819293784.webp";
import commentImage from "./../assets/images/1e6137f8639ce1c4c4254fb5911a2e43.webp";
import afterCareImage from "./../assets/images/WhatsApp Image 2024-11-04 at 12.39.02_a4e445e8.webp";
import programImage1 from "../assets/images/family program.webp";
import programImage2 from "../assets/images/WhatsApp Image 2024-11-07 at 14.48.30_9e8a5e0b.webp";
import courseImage1 from "../assets/images/WhatsApp Image 2024-12-26 at 22.18.16_2ed51c98.webp";
import courseImage2 from "../assets/images/WhatsApp Image 2024-11-04 at 12.12.01_0a786a37.webp";
import courseImage3 from "../assets/images/WhatsApp Image 2024-11-01 at 23.57.21_9b97d2d3.webp";
import courseImage4 from "../assets/images/WhatsApp Image 2024-11-04 at 12.03.04_8c5dd114.webp";
import courseImage5 from "../assets/images/WhatsApp Image 2024-11-04 at 12.13.56_18f9d2b2.webp";
import courseImage6 from "../assets/images/my recovery journal.webp";

import user1 from "../assets/images/indian-asian-man-with-light-spring-jacket-looking-off-side-isolated-green-background-jpg-portrait-image_1017677-28779.webp";
import user2 from "../assets/images/smiling-adult-latino-man-with-brown-curly-hair-photo-portrait-casual-person-city-street-photorealistic-ai-generated-horizontal-illustration_107173-52024.webp";
import user3 from "../assets/images/showing-number-two-with-fingers_1187-324022.webp";
import user4 from "../assets/images/positive-man-on-clean-background-photo.webp";
import user5 from "../assets/images/positive-man-on-clean-background-photo (1).webp";
import user6 from "../assets/images/businessman-wearing-suit-smiling-camera-portrait-generative-ai_1000174-2720.webp";
import user7 from "../assets/images/ai-generated-portrait-of-a-young-man-no-facial-expression-facing-the-camera-isolated-white-background-ai-generative-photo.webp";
import user8 from "../assets/images/WhatsApp Image 2024-11-23 at 17.30.50_184ec207.webp";
import user9 from "../assets/images/WhatsApp Image 2024-11-23 at 17.30.50_d5512b08.webp";
import user10 from "../assets/images/WhatsApp Image 2024-11-23 at 17.30.51_070c87e2.webp";
import user11 from "../assets/images/WhatsApp Image 2024-11-23 at 17.30.51_75e048b1.webp";
import user12 from "../assets/images/WhatsApp Image 2024-11-23 at 17.30.52_89d4d671.webp";
import user13 from "../assets/images/WhatsApp Image 2024-11-23 at 17.30.52_91e6c61f.webp";
import user14 from "../assets/images/WhatsApp Image 2024-11-23 at 17.30.53_3c4ffe79.webp";
import user15 from "../assets/images/resize.webp";
import user16 from "../assets/images/image2121212s.webp";
import user17 from "../assets/images/most-attractive-man-in-a-country-v0-9pwswqjkat8b1.webp";
import user18 from "../assets/images/100k-ai-faces-6.webp";

import phase1 from "../assets/images/WhatsApp Image 2024-11-07 at 15.19.33_119caaab.webp";
import phase2 from "../assets/images/WhatsApp Image 2024-11-04 at 11.56.17_a7219ec5.webp";
import phase3 from "../assets/images/WhatsApp Image 2024-11-04 at 11.51.01_92675b1a.webp";

import blogImage1 from "../assets/images/blog1.webp";
import blogImage2 from "../assets/images/blog2.webp";
import blogImage3 from "../assets/images/blog3.webp";
import blogImage4 from "../assets/images/blog4.webp";
import blogImage5 from "../assets/images/blog5.webp";
import blogImage6 from "../assets/images/blog6.webp";
import blogImage7 from "../assets/images/blog7.webp";
import blogImage8 from "../assets/images/blog8.webp";
import blogImage9 from "../assets/images/blog9.webp";
import blogImage10 from "../assets/images/blog10.webp";
import blogImage11 from "../assets/images/blog11.webp";
import blogImage12 from "../assets/images/blog12.webp";
import blogImage13 from "../assets/images/blog13.webp";
import blogImage14 from "../assets/images/blog14.webp";
import blogImage15 from "../assets/images/blog15.webp";
import blogImage16 from "../assets/images/blog16.webp";
import blogImage17 from "../assets/images/blog17.webp";
import blogImage18 from "../assets/images/blog18.webp";

export const useNavbarItems = () => {
  const { t } = useTranslation();

  return [
    { id: 1, name: t("header.home"), path: "/" },
    { id: 6, name: t("header.about_us"), path: "/our_story" },
    { id: 4, name: t("header.our_team"), path: "/our-team" },
    // { id: 2, name: t("header.elite_wellbeing"), path: "/elite_wellbeing" },
    // { id: 3, name: t("header.success_story"), path: "/success_story" },
    { id: 5, name: t("header.blog"), path: "/blogs" },
  ];
};

export const ProgramItemsDropdown = (t) => {
  return [
    {
      label: <Link to="/program">{t("titles.allPrograms")}</Link>,
      key: "4",
    },
    {
      label: <Link to="/program/1">{t("titles.familyProgram")}</Link>,
      key: "0",
    },
    {
      label: <Link to="/program/2">{t("titles.onlineProgram")}</Link>,
      key: "1",
    },
    {
      label: <Link to="/program/3">{t("titles.afterCareProgram")}</Link>,
      key: "2",
    },
    {
      label: <Link to="/program/4">{t("titles.detox")}</Link>,
      key: "3",
    },
  ];
};

export const ourValues = [
  {
    id: 1,
    icon: Privacy(),
    name: "Privacy",
    description:
      "and complete confidentiality in dealing with patients and their information.",
  },
  {
    id: 2,
    icon: Privacy(),
    name: "Commitment",
    description:
      "to applying the latest scientific information in the addiction treatment journey.",
  },
  {
    id: 3,
    icon: SincereIntention(),
    name: "Sincere intention",
    description: "in helping patients with addiction and their families.",
  },
  {
    id: 4,
    icon: HighAccuracy(),
    name: "high accuracy",
    description:
      "Dealing with and professionalism according to ethical principles.",
  },
  {
    id: 5,
    icon: Transparency(),
    name: "Transparency",
    description: "in vision, goals, and purpose.",
  },
];

export const ourOffers = [
  {
    id: 1,
    name: "Privacy",
    image: [detoxImage6, detoxImage7, detoxImage8],
    description:
      "Detoxification and control of withdrawal symptoms in the best facilities in Istanbul, with the possibility of hospital work outside.",
  },
  {
    id: 2,
    name: "Commitment",
    image: [
      medicalCareImage6,
      medicalCareImage7,
      medicalCareImage8,

    ],
    description:
      "Intensive medical care from specialists, including all necessary laboratory tests and continuous medical supervision.",
  },
  {
    id: 3,
    name: "Sincere intention",
    image: [
      supervisionImage5,
      supervisionImage6,
      supervisionImage7,
    ],
    description:
      "Supervision by specialists in various fields applying the latest treatment methods, fluent in Arabic and of Arab origin.",
  },
  {
    id: 7,
    name: "Transparency",
    image: [
      accommodationImage1,
      accommodationImage2,
      accommodationImage3,
      accommodationImage4,
      accommodationImage5,
      accommodationImage6,
    ],
    description:
      "Accommodation in a resort surrounded by nature with beautiful views to ensure the highest levels of psychological and mental comfort, including a private car and driver for each individual.",
  },
  {
    id: 4,
    name: "high accuracy",
    image: [lifeCoachImage4, lifeCoachImage5, lifeCoachImage6],
    description:
      "Life coach available throughout the recovery period for life skills training.",
  },
  {
    id: 5,
    name: "Transparency",
    image: [tripsImage5, tripsImage6, tripsImage7],
    description:
      "Touristic trips in different cities in Turkey to explore every weekend.",
  },
  {
    id: 6,
    name: "Transparency",
    image: [
      trainingImage7,
      trainingImage8,
      trainingImage9,

    ],
    description:
      "Life skills training with a focus on personal development through daily varied activities.",
  },
];

export const breakpoints = {
  640: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 10,
  },
  1024: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
};

export const coachingDataFunc = (t) => {
  return [
    {
      name: t("our_team.saad"),
      title: t("our_team.saad_title"),
      img: saadImage,
      path: "details",
      category: "recovery",
      id: "1",
      description: t("our_team.saad_desc"),
    },
    {
      name: t("our_team.huzeyfe"),
      title: t("our_team.huzeyfe_title"),
      img: hozifaImage,
      path: "details",
      category: "psychiatrist",
      id: "2",
      description: t("our_team.huzeyfe_desc"),
    },
    {
      name: t("our_team.younis"),
      title: t("our_team.younis_title"),
      img: younisImage,
      path: "details",
      category: "psychologist",
      id: "3",
      description: t("our_team.younis_desc"),
    },
    {
      name: t("our_team.tariq"),
      title: t("our_team.tariq_title"),
      img: tarekImage,
      path: "details",
      category: "psychiatrist",
      id: "20",
      description: t("our_team.tariq_desc"),
    },
    {
      name: t("our_team.osama"),
      title: t("our_team.osama_title"),
      img: usamaImage,
      path: "details",
      category: "recovery",
      id: "4",
      description: t("our_team.osama_desc"),
    },
    {
      name: t("our_team.rahma"),
      title: t('our_team.rahma_title'),
      img: rahmaImage,
      path: "details",
      category: "management",
      id: "11",
      description: t('our_team.rahma_desc'),
    },
    {
      name: t("our_team.issam"),
      title: t("our_team.issam_title"),
      img: issamImage,
      path: "details",
      category: "fitness",
      id: "5",
      description: t("our_team.issam_desc"),
    },
    {
      name: t("our_team.ammar"),
      title: t("our_team.ammar_title"),
      img: ammarImage,
      path: "details",
      category: "recovery",
      id: "12",
      description: t("our_team.ammar_desc"),
    },
    {
      name: t("our_team.ahlam"),
      title: t("our_team.ahlam_title"),
      img: ahlamImage,
      path: "details",
      category: "familyCoach",
      id: "15",
      description: t("our_team.ahlam_desc"),
    },
    {
      name: t("our_team.dania"),
      title: t("our_team.dania_title"),
      img: daniaImage,
      path: "details",
      category: "management",
      id: "10",
      description: t("our_team.dania_desc"),
    },
    {
      name: t("our_team.sami"),
      title: t('our_team.sami_title'),
      img: samiImage,
      path: "details",
      category: "recovery",
      id: "7",
      description: t('our_team.sami_desc'),
    },
    {
      name: t("our_team.maram"),
      title: t("our_team.maram_title"),
      img: maramImage,
      path: "details",
      category: "management",
      id: "6",
      description: t("our_team.maram_desc"),
    },
    {
      name: t("our_team.nashat"),
      title: t("our_team.nashat_title"),
      img: nashatImage,
      path: "details",
      category: "management",
      id: "8",
      description: t("our_team.nashat_desc"),
    },
    {
      name: t("our_team.giorgos"),
      title: t("our_team.giorgos_title"),
      img: giorgosImage,
      path: "details",
      category: "management",
      id: "13",
      description: t("our_team.giorgos_desc"),
    },
    {
      name: t("our_team.hesham"),
      title: t("our_team.hesham_title"),
      img: heshamImage,
      path: "details",
      category: "management",
      id: "14",
      description: t("our_team.hesham_desc"),
    },
    {
      name: t("our_team.amr"),
      title: t("our_team.amr_title"),
      img: amrImage,
      path: "details",
      category: "management",
      id: "16",
      description: t("our_team.amr_desc"),
    },
    {
      name: t("our_team.abdelrazzaq"),
      title: t("our_team.abdelrazzaq_title"),
      img: abdelrazzaqImage,
      path: "details",
      category: "fitness",
      id: "17",
      description: t("our_team.abdelrazzaq_desc"),
    },
    {
      name: t("our_team.taha"),
      title: t("our_team.taha_title"),
      img: tahaImage,
      path: "details",
      category: "management",
      id: "18",
      description: t("our_team.taha_desc"),
    },
    {
      name: t("our_team.mohammed"),
      title: t('our_team.mohammed_douki_title'),
      img: doukiImage,
      path: "details",
      category: "recovery",
      id: "19",
      description: t('our_team.mohammed_douki_desc'),
    },
  ];
};

export const comments = [
  {
    image: commentImage,
    content:
      "I was so naughty and impulsive and with the love of adventures and anything in disasters that I got into, literally naughty and I was curious to try anything in the world, and I drank alcohol and loved doing Joe. My concern was how to stay up and be lined and I smoked weed or whatever I was traveling. After suddenly my friend said in the beans and shaw your body in the haram drink this treatment leave the drink and try Had... I agreed. Honestly I was Schaev it made sense and was Nirontaine on her time I actually tried after an hour of joy!! Hon Balsht's predecessor and the days increased in an unspeakably terrible way, because it was hard to get out of it, and I developed a lot, and I went back to drinking and I got into all the drug varieties. reed more",
    age: "20",
    rate: 4,
    member: "Olivia Rhya",
  },
  {
    image: commentImage,
    content:
      "I was so naughty and impulsive and with the love of adventures and anything in disasters that I got into, literally naughty and I was curious to try anything in the world, and I drank alcohol and loved doing Joe. My concern was how to stay up and be lined and I smoked weed or whatever I was traveling. After suddenly my friend said in the beans and shaw your body in the haram drink this treatment leave the drink and try Had... I agreed. Honestly I was Schaev it made sense and was Nirontaine on her time I actually tried after an hour of joy!! Hon Balsht's predecessor and the days increased in an unspeakably terrible way, because it was hard to get out of it, and I developed a lot, and I went back to drinking and I got into all the drug varieties. reed more",
    age: "20",
    rate: 4,
    member: "Olivia Rhya",
  },
  {
    image: commentImage,
    content:
      "I was so naughty and impulsive and with the love of adventures and anything in disasters that I got into, literally naughty and I was curious to try anything in the world, and I drank alcohol and loved doing Joe. My concern was how to stay up and be lined and I smoked weed or whatever I was traveling. After suddenly my friend said in the beans and shaw your body in the haram drink this treatment leave the drink and try Had... I agreed. Honestly I was Schaev it made sense and was Nirontaine on her time I actually tried after an hour of joy!! Hon Balsht's predecessor and the days increased in an unspeakably terrible way, because it was hard to get out of it, and I developed a lot, and I went back to drinking and I got into all the drug varieties. reed more",
    age: "20",
    rate: 4,
    member: "Olivia Rhya",
  },
];

export const programsFunc = (t) => {
  return [
    {
      name: t("titles.familyProgram"),
      desc: t("content.familyDescription"),
      id: "1",
      path: "program",
      image: programImage1,
      title: t('content.familyTitle'),
      link: "https://wa.me/+905551675716",
      programData: {
        subPrograms: [],
        description: t('content.familyDesc'),
        offerings: [
          {
            title: t('content.familyOffer1'),
            details:
              "These sessions will focus on understanding your unique addiction challenges and setting the foundation for your recovery journey.",
          },
          {
            title: t('content.familyOffer2'),
            details:
              "A consultation with the team’s psychiatrist to assess your mental health and discuss any necessary medical interventions.",
          },
          {
            title: t('content.familyOffer3'),
            details:
              "These in-depth therapeutic sessions with the team’s psychologist will address the emotional and psychological aspects of your addiction, offering tools and strategies for coping and healing.",
          },
        ],
        keyDeliverables: [
          {
            title: t('content.familyDeliverablesTitle1'),
            details:
            t('content.familyDeliverablesDesc1'),
          },
          {
            title: t('content.familyDeliverablesTitle2'),
            details:
            t('content.familyDeliverablesDesc2'),
          },
          {
            title: t('content.familyDeliverablesTitle3'),
            details:
            t('content.familyDeliverablesDesc3'),
          },
          {
            title: t('content.familyDeliverablesTitle4'),
            details:
            t('content.familyDeliverablesDesc4'),
          },
        ],
        callToAction:
          "Don't miss the chance to be part of the supportive network. Join us now and take the first step toward healing and growth!",
      },
    },
    {
      name: t("titles.onlineProgram"),
      desc: t("content.onlineDescription"),
      image: programImage2,
      id: "2",
      path: "program",
      link: "https://wa.me/+905551675716",
      title: null,
      programData: {
        subPrograms: [
          {
            id: "1",
            path: "program-details",
            name: t('content.onlineTitle1'),
            description: t('content.onlineDesc1'),
            offerings: [
              {
                title: "Comprehensive Recovery Plan:",
                subSessions: [
                  "A fully customized recovery plan that addresses your specific circumstances, providing detailed steps for your treatment journey.",
                ],
              },
              {
                title: "Access to Experts:",
                subSessions: [
                  "You will receive contact details for all relevant doctors, therapists, and trainers involved in your recovery process, as well as recommended facilities.",
                ],
              },
              {
                title: "Scientific Nutrition Plan:",
                subSessions: [
                  "A detailed, personalized nutrition plan designed by a specialist, tailored to your health and recovery needs.",
                ],
              },
              {
                title: "Personalized Fitness Plan:",
                subSessions: [
                  "A fitness routine that aligns with your physical condition, helping to boost your overall well-being during recovery.",
                ],
              },
              {
                title: "Resources for Recovery:",
                subSessions: [
                  "You’ll be provided with a list of recommended courses, books, and helpful tools to support your journey to sobriety.",
                ],
              },
              {
                title: "WhatsApp Follow-Up:",
                subSessions: [
                  "For 10 days, you’ll have access to ongoing support via WhatsApp, where you can ask questions and receive updates or adjustments to your recovery plan.",
                ],
              },
            ],
            programComponents: [
              {
                title: "Two Sessions with Recovery Specialist Saad Al Mahmoud:",
                subSessions: [
                  "These sessions will focus on understanding your unique addiction challenges and setting the foundation for your recovery journey.",
                ],
              },
              {
                title: "Psychiatrist Session:",
                subSessions: [
                  "A session with the team’s psychiatrist to evaluate your mental health and explore any necessary medical interventions.",
                ],
              },
              {
                title: "Extended Session with the Psychologist:",
                subSessions: [
                  "A deep, therapeutic session with the team’s psychologist to address emotional and psychological factors contributing to your addiction.",
                ],
              },
              {
                title:
                  "Extended Session with the Fitness Trainer and Nutrition Specialist:",
                subSessions: [
                  "A personalized session to design a scientifically backed nutrition plan and a fitness routine that suits your condition and recovery goals.",
                ],
              },
            ],
          },
          {
            id: "2",
            path: "program-details",
            name: t('content.onlineTitle2'),
            description: t('content.onlineDesc2'),
            offerings: [
              {
                title: "10-Day Assessment & Full Recovery Plan Development:",
                subSessions: [
                  "Two Assessment Sessions: Diagnostic sessions with a specialized therapist and Saad Al-Mahmoud to understand your addiction problem. A psychiatrist may also be consulted if necessary.",
                  "Session with a Nutrition and Fitness Expert: Evaluation of your physical health to ensure the development of a suitable nutrition and exercise plan tailored to your circumstances.",
                  "Complete Recovery Plan: A personalized and detailed recovery roadmap designed specifically for your situation.",
                  "Access to Professionals: You will receive a comprehensive list of therapists, doctors, trainers, and locations required in your recovery plan.",
                  "Resources: Access to courses, books, and support schedules relevant to your recovery journey.",
                  "10 Days of Intensive Follow-Up: Personalized support via WhatsApp with Saad Al-Mahmoud and team members to answer any questions and make adjustments to the plan.",
                ],
              },
              {
                title: "30-Day Intensive Group Recovery Program:",
                subSessions: [
                  "12 Group Sessions (3 sessions per week), including: 4 Group Therapy Sessions: Focused on your specific type of addiction, with a specialized addiction therapist, once a week. 4 Group 12-Step Program Sessions: Led by 12-step expert therapist, Mohammed Al-Dawaki, once a week. 4 Group Support Sessions: Exclusive to challenge participants, with Saad Al-Mahmoud and the RESTART team, once a week.",
                  "Daily Group Support: A dedicated WhatsApp group for daily communication with Saad and therapists, with responses within 12 hours.",
                  "Daily Fitness and Nutrition Support: Direct communication with the fitness coach and nutrition specialist.",
                ],
              },
              {
                title: " 60-Day Recovery Strengthening Program:",
                subSessions: [
                  "24 Group Sessions (3 sessions per week), including: 8 Cognitive Behavioral Therapy (CBT) Sessions: Focused on life rebuilding and correcting mistakes, led by a behavioral therapist. 8 Group 12-Step Program Sessions: Continuation and application of the 12 steps with Mohammed Al-Dawaki. 8 Weekly Support Group Sessions: Focused on relapse prevention and recovery reinforcement with Saad Al-Mahmoud and the RESTART team.",
                  "Daily Support: Ongoing support via WhatsApp with responses within 24 hours.",
                  "Daily Personal Support: Regular communication with your fitness coach and nutrition specialist for any questions and guidance.",
                ],
              },
              {
                title: "Personalized Fitness and Nutrition Program:",
                subSessions: [
                  "Customized Nutrition Plan: A flexible, science-based nutrition plan tailored to your goals, provided by a team nutritionist.",
                  "Tailored Fitness Plan: A personalized fitness plan designed according to your conditions and guided by a professional trainer.",
                  "Daily Follow-Up: Personal daily follow-up with the trainer to explain exercises, nutrition plans, and answer any questions throughout the 100-day challenge.",
                  "Meal Recommendations: A list of restaurants and meals in your city that align with your nutrition plan.",
                  "Shopping List: A detailed shopping list to help prepare your meals.",
                ],
              },
              {
                title: "Relapse Program:",
                subSessions: [
                  "Personal Call with Saad Al-Mahmoud: To understand the reasons for relapse.",
                  "10 Days of Intensive Follow-Up: Immediate support from our team to ensure you get back on track and stick to the recovery plan after a relapse.",
                ],
              },
              // vip
              {
                title: "Additional Support:",
                vip: true,
                subSessions: [
                  "Intensive follow-up with Saad Al-Mahmoud and your chosen therapist, including 30 personal therapy sessions that can be used within a year.",
                ],
              },
              {
                title: "Year-Long Support:",
                vip: true,
                subSessions: [
                  "Saad Al-Mahmoud will personally oversee your recovery journey with continuous follow-up for one full year.",
                ],
              },
            ],
            programComponents: [],
          },
          {
            id: "3",
            path: "program-details",
            name: t('content.onlineTitle3'),
            description: t('content.onlineDesc3'),
            offerings: [
              { title: "Younis Abu Hashish", subSessions: [] },
              { title: "Ammar", subSessions: [] },
              { title: "Osama Al-Zahouri", subSessions: [] },
              { title: "Sami Al-Baziri", subSessions: [] },
              { title: "Dr.Huzeyfe Barham", subSessions: [] },
              { title: "Dr.Tariq Hajawi", subSessions: [] },
              { title: "Mohammed Al-Dawaki", subSessions: [] },
              { title: "Issam Al-Zaitouni", subSessions: [] },
            ],
            programComponents: [],
          },
        ],
        description: "",
        offerings: [],
        keyDeliverables: [],
        callToAction: "",
      },
    },
    {
      name: t("titles.afterCareProgram"),
      desc: t("content.aftercareDescription"),
      image: afterCareImage,
      id: "3",
      path: "program",
      title: null,
      link: "https://wa.me/+905551675716",
      programData: {
        subPrograms: [
          {
            id: "4",
            path: "program-details",
            name: t('content.aftercareTitle1'),
            description: t('content.aftercareDesc1'),
            offerings: [
              {
                title:
                  "8 Therapy Sessions: 4 sessions for the person in recovery and 4 sessions for family members",
                subSessions: [],
              },
              {
                title:
                  "4 Life Coaching Sessions: to enhance support and guidance",
                subSessions: [],
              },
              {
                title:
                  "8 Fitness Training Sessions: to maintain physical fitness and activity",
                subSessions: [],
              },
              {
                title:
                  "Appointment Coordination and Continuous Follow-Up: to ensure adherence to the program",
                subSessions: [],
              },
            ],
            duration: "One month",
          },
          {
            id: "5",
            path: "program-details",
            name: t('content.aftercareTitle2'),
            description: t('content.aftercareDesc2'),
            offerings: [
              {
                title:
                  "8 Therapy Sessions: 4 sessions for the person in recovery and 4 sessions for family members",
                subSessions: [],
              },
              {
                title: "2 Sessions with Coach Saad Al-Mahmoud",
                subSessions: [],
              },
              {
                title: "2 Life Coaching Sessions",
                subSessions: [],
              },
              {
                title:
                  "8 Fitness Training Sessions: for physical fitness follow-up",
                subSessions: [],
              },
            ],
            duration: "",
          },
          {
            id: "6",
            path: "program-details",
            name: t('content.aftercareTitle3'),
            description: t('content.aftercareDesc3'),
            offerings: [
              {
                title:
                  "6 Sessions with Saad Al-Mahmoud during the program period",
                subSessions: [],
              },
              {
                title:
                  "24 Sessions divided between psychotherapy and recovery coaching for continuous support, as follows : ",
                subSessions: [
                  "6 sessions with Coach Saad Al-Mahmoud",
                  "6 sessions with Therapist Younis Abu Hashish",
                  "6 sessions with Coach Ammar",
                  "6 sessions with Coach Mohammed Al-Dawaki",
                ],
              },
            ],
            duration: "100 days",
          },
          {
            id: "7",
            path: "program-details",
            name: t('content.aftercareTitle4'),
            description: t('content.aftercareDesc4'),
            offerings: [],
            duration: "",
          },
        ],
        description: "",
        offerings: [],
        keyDeliverables: [],
        callToAction: "",
      },
    },
    {
      name: t("titles.detox"),
      desc: t("content.detoxDescription"),
      id: "4",
      path: "program",
      image: detoxImage5,
      title: "",
      link: "https://wa.me/+905551675716",
      programData: {
        subPrograms: [],
        description: t('content.detoxDesc'),
        keyDeliverables: [
          {
            title: t('content.detoxDeliverablesTitle1'),
            details: t('content.detoxDeliverablesDesc1'),
          },
          {
            title: t('content.detoxDeliverablesTitle2'),
            details: t('content.detoxDeliverablesDesc2'),
          },
          {
            title: t('content.detoxDeliverablesTitle3'),
            details: t('content.detoxDeliverablesDesc3'),
          },
        ],
        callToAction: "",
      },
    },
  ];
};

export const coursesFunc = (t) => {
  return [
    {
      name: t("courses.memories"),
      img: courseImage1,
      path: "courses",
      id: "1",
      description: t("courses.memoriesDesc"),
      courseURL:
        "https://drive.google.com/file/d/1tCGQe5TOTyxgbE6xL1ntgxv8n8cmILHQ/view?usp=sharing",
    },
    {
      name: t("courses.helpSomeoneOvercome"),
      img: courseImage2,
      path: "courses",
      id: "2",
      description: t("courses.helpSomeoneOvercomeDesc"),
      courseURL:
        "https://drive.google.com/file/d/1A0VY4gZDt9fU2SlLPJKd2olxmN3dT_Sr/view?usp=sharing",
    },
    {
      name: t("courses.helpSomeone"),
      img: courseImage3,
      path: "courses",
      id: "3",
      description: t("courses.helpSomeoneDesc"),
      courseURL:
        "https://drive.google.com/file/d/1I7F9pV1A_ZYO6XTcuSas823VNYbfiDIQ/view?usp=sharing",
    },
    {
      name: t("courses.readyFitness"),
      img: courseImage4,
      path: "courses",
      id: "4",
      description: t("courses.readyFitnessDesc"),
    },
    {
      name: t("courses.lifeFree"),
      img: courseImage5,
      path: "courses",
      id: "5",
      description: t("courses.lifeFreeDesc"),
      courseURL:
        "https://youtube.com/playlist?list=PLxV5FDnhxI3JYbB6cNvHYR4iMfURtgu1D&si=z5esdfNJi9Wnuyhc",
    },
    {
      name: t("courses.recovery"),
      img: courseImage6,
      path: "courses",
      id: "6",
      description: t("courses.recoveryDesc"),
      courseURL:
        "file:///C:/Users/Usama/AppData/Local/Microsoft/Windows/INetCache/IE/1YXHQBGX/%D8%B0%D9%83%D8%B1%D9%8A%D8%A7%D8%AA%20%D9%85%D8%AF%D9%85%D9%86%20%D8%A7%D9%84%D9%86%D8%B3%D8%AE%D8%A9%20%D8%A7%D9%84%D8%A7%D9%94%D8%AE%D9%8A%D8%B1%D8%A9[1].pdf",
    },
  ];
};
export const commentsData = [
  {
    top: "3%",
    left: "10%",
    image: user1,
    size: 60,
    content:
      "استفدت الحمدلله وتعلمت أن لكل انسان طاقة ويستطيع توفيرها في ماهو مفيد",
  },
  {
    top: "10%",
    left: "50%",
    image: user2,
    size: 60,
    content: "نعم وبشدة، غير لي حياتي للأفضل إن شاء الله",
  },
  {
    top: "50%",
    left: "10%",
    image: user3,
    size: 58,
    content: "نعم ، معلومات جديدة كثيرة مهمة ومفيدة",
  },
  {
    top: "30%",
    left: "60%",
    image: user4,
    size: 70,
    content: "الفريق مميز ومن افضل ما يكون",
  },
  {
    top: "80%",
    left: "80%",
    image: user5,
    size: 70,
    content: "الاهتمام والتطرف الى كل الجوانب المتعلقه بالادمان",
  },
  {
    top: "0%",
    left: "20%",
    image: user6,
    size: 64,
    content: "الاهتمام والنصح والإرشاد من قِبل المدربين",
  },
  {
    top: "10%",
    left: "90%",
    image: user7,
    size: 68,
    content: "المعلومات القيمة والجديدة بالنسبة لي",
  },
  {
    top: "15%",
    left: "5%",
    image: user8,
    size: 70,
    content:
      "المعنويه العاليه وتجديد ثقتي فنفسي وتغير تفكيري حتى اسلويي الى الافضل بسبب الاسلوب الراقي الي بتعاملو فيه تحياتي كوتش سعد اسامه والدوعكي ..",
  },
  {
    top: "10%",
    left: "70%",
    image: user9,
    size: 70,
    content: "زرع فيني الأمل والثقة بالله",
  },
  {
    top: "60%",
    left: "50%",
    image: user10,
    size: 58,
    content: "اثره ايجابي المزاج جيد وهادئ وبدأت الاحظ التركيز والثقه",
  },
  {
    top: "65%",
    left: "30%",
    image: user11,
    size: 74,
    content:
      "اثر بشكل ايجابي كيير والاهل وزوجتي بلشو يلاحظو تفكيري واسلوبي تغير بناحيه ايجابيه وانا سعيد بسبب هاشي ..بس عندي تخويت وهبل من شخصيتي الي لسا بستكشف فيها نفسي 🥵😂",
  },
  {
    top: "20%",
    left: "20%",
    image: user12,
    size: 70,
    content:
      "فعليا ممتازه من ناحية التجربة ومحاكاة الشعور الفعلي لمن يحاول التخلص من الادمان",
  },
  {
    top: "20%",
    left: "30%",
    image: user13,
    size: 64,
    content: "جميل واستطيع القول أنها تجربة ناجحة ان شاءالله",
  },
  { top: "40%", left: "90%", image: user1, size: 58, content: "محترف" },
  {
    top: "80%",
    left: "5%",
    image: user14,
    size: 72,
    content: "الله يعطيكم العافية ما قصرتوا معنا والله",
  },
  {
    top: "65%",
    left: "15%",
    image: user15,
    size: 64,
    content: "الله يوفقكم ويكتب لكم الاجر يا رب ولدي اموره طيبة جدا",
  },
  {
    top: "20%",
    left: "40%",
    image: user16,
    size: 60,
    content: "رحلة مميزة وفريدة من نوعها",
  },
  {
    top: "55%",
    left: "75%",
    image: user17,
    size: 54,
    content:
      "كانت صعبة فالبداية و لكن ابدت نتائج رائعة في نهاية العلاج : منها تغيير النمط الفكري و تغيير الاهتمامات لأمور إيجابية",
  },
  {
    top: "30%",
    left: "80%",
    image: user18,
    size: 76,
    content: "البرنامج غير تقليدي و متنوع وفيه الكثير من الانضباط",
  },
];

export const commentsEliteWellbeingData = [
  {
    image: user1,
    size: 70,
    top: "10%",
    left: "10%",
    content:
      "اكبر شيء قدمتوه لابني انه خليتو عنده شغف كبير بالرياضة وهالشي اثر على كل شي بشكل ايجابي الصراحة ما توقعت الرياضة لهدرجة بتأثر",
  },
  {
    image: user2,
    size: 70,
    top: "20%",
    left: "35%",
    content:
      "بالنسبة لابني كان الانستجرام عنده في بنات قبل اكثر من شهر وربع عاديين ب ما نفضلهن, الحين اختفوا كلهم , وصارت صفحته واهتماماته وكل الفيديوهات اللي يتابعها حق كمال الاجسام",
  },
  {
    image: user3,
    size: 62,
    top: "40%",
    left: "80%",
    content:
      "الحين ولدي اموره طيبة , حاليا اهتمامه كشخة وطلعات وجي بس بشكل محدود , يقول احس اني نظيف وغير ومستمتع بدون خوف فالحمد لله كثيرا",
  },
  {
    image: user4,
    size: 62,
    top: "15%",
    left: "80%",
    content:
      "الحمدالله استجاب ابني كثيرا للعلاج لأنه كان برنامجا متكاملا من لياقة بدنية إلى غذاء صحي و طبعا اهم شي الجلسات النفسية ... وطريقة البرنامج مختلفة عن البرامج التقليدية ، أن يسكن ابني بسكن لحاله و معاه التلفون و قدرة الحركة بحرية برفقة مرافق لعبت دور كبير في تحفيزه لتلقي العلاج و الثقة بالبرنامج …",
  },
  {
    image: user5,
    size: 78,
    top: "35%",
    left: "15%",
    content:
      "خدمة المرافقين والمعالجين والمدربين رائعة و متوفرين للتواصل على مدار اليوم كامل تقريبا لاجوبة اي من استفسارات الاهل",
  },
  {
    image: user10,
    size: 64,
    top: "70%",
    left: "15%",
    content:
      "الفريق العلاجي يتواصل مع الاهل بشكل مباشر و بشكل يومي تقريبا في بدايه العلاج حتى استقرار الحالة",
  },
  {
    image: user7,
    size: 74,
    top: "80%",
    left: "60%",
    content:
      "الحمدلله اولا و اخيرا ، لكم الفضل بعد الله سبحانه و تعالي في هداية ابننا",
  },
  {
    image: user8,
    size: 72,
    top: "65%",
    left: "75%",
    content:
      "سرعة التواصل وتحديث اول باول عن الحالة وخبرة في التعامل وايضاً بحكم ان معظم الحالات لديكم شباب وسنكم قريب منهم كان له دور كبير في تقبل الحالة فريقكم",
  },
  {
    image: user9,
    size: 56,
    top: "80%",
    left: "45%",
    content:
      "تم تغيير تفكير ابننا و زرع حب الرياضة لديه مما اثر على تفكيره و على روتينه اليومي بشكل ايجابي",
  },
  {
    image: user6,
    size: 80,
    top: "10%",
    left: "50%",
    content:
      "كانت قيمة البرنامج مرتفعة بالنسبة لنا ، و لكن نقدر ما تم تقديمه لابننا بشكل دقيق و مناسب لحالته",
  },
];

export const testimonials = [
  {
    title: "الحمدالله استجاب ابني كثيرا للعلاج",
    desc: "الحمدالله استجاب ابني كثيرا للعلاج لأنه كان برنامجا متكاملا من لياقة بدنية إلى غذاء صحي و طبعا اهم شي الجلسات النفسية ... وطريقة البرنامج مختلفة عن البرامج التقليدية ، أن يسكن ابني بسكن لحاله و معاه التلفون و قدرة الحركة بحرية برفقة مرافق لعبت دور كبير في تحفيزه لتلقي العلاج و الثقة بالبرنامج ",
  },
  {
    title: "بعدها بشهر بدأ تغيير بسيط",
    desc: "كانت صعبة جدا فالبدايه للفريق العلاجي و للاهل بشكل اخص و ذلك بسبب مقاومه ابننا للكثير من الخطة العلاجية ، بعدها بشهر بدأ تغيير بسيط و بعدها بشهرين تم الملاحظة على بدايه التغيير في السلوك و الاهتمامات والتوجه للرياضة بشكل مفرط مما ادى الى تنظيم الاكل و النوم و الذي ساعد بشكل كبير فالتشافي .",
  },
  {
    title: "آمنت ليتعافى ابني من الإدمان",
    desc: "نحن نضع المال من أجل شراء سيارة أو تحسين منزل أو سفر ... ابنك او بنتك أولى بهذا المبلغ ... آمنت ليتعافى ابني من الإدمان علي أن أنفق مبلغا وقدره ، الأهل فيهم يتسلفوا جزء من المبلغ و جزء توفير و الله ما راح تندمون على هالشي ...البرنامج يحاكي عقول الشباب و يتماشى مع شخصياتهم ، البرنامج مقنع جدا بالنسبة للشباب و يعطيهم أمل كبير في التعافي",
  },
  {
    title: "خضع لعلاج طبي لمدة شهر كامل في إحدى المصحات",
    desc: "عانى ابننا من الادمان لمدة تقريبا ٤ سنوات و في بدايه عمر البلوغ ، و كان من الصعب جدا السيطرة على وضعه خلال هذه السنوات لانه كان محاط بصحبة سيئة جداً ، خضع لعلاج طبي لمدة شهر كامل في إحدى المصحات ، و لكن كان علاجي جسدي غير سلوكي ، مما أدى لانتكاسه مباشره .",
  },
];

export const phases = [
  {
    id: 1,
    title: "Detox",
    description: [
      "Conduct comprehensive laboratory tests and detoxification procedures.",
      "Ensure all organs are functioning properly, and there are no deficiencies in any vitamins or minerals in the body. Confirm the participant is free from any diseases.",
      "This phase is done in collaboration with the Shadow Clinic and the French Hospital in Turkey under the supervision of Dr. Hudhaifa Barham.",
    ],
    duration: "2 to 4 weeks",
    image: phase1,
  },
  {
    id: 2,
    title: "Brain Cell Renewal and Comprehensive Program by Restart",
    description: [
      "Identify the reasons behind the participant's addiction and address them at their roots, providing the necessary treatment through a system of various non-pharmacological therapies.",
      "Brain cell renewal and a comprehensive approach program that addresses the body, mind, and spirit. The duration is 3 to 5 months, with intensive and comprehensive follow-up with the family. It also includes recreational trips and activities to develop hobbies.",
    ],
    duration: " 3 to 6 months",
    image: phase2,
  },
  {
    id: 3,
    title: "Community Integration Aftercare",
    description: [
      "Help participants reach a level of maturity in social, psychological, and spiritual aspects. Assist participants in setting life goals with life coaches.",
      "Prepare participants for reintegration into society, providing support and follow-up through the Restart support group.",
      "They can move to live in one of the recovery houses in the city where they reside, allowing them to attend university and work in a safe place. There will be intensive and comprehensive follow-up with the family.",
    ],
    duration: "Sustainably",
    image: phase3,
  },
];

export const blogsFunc = (t) => {
  return [
    {
      path: "/blog",
      id: "1",
      image: blogImage1,
      points: t("blogs.blogPoints1"),
      title: t("blogs.blogtitle1"),
      desc: t("blogs.blogDesc1"),
    },
    {
      path: "/blog",
      id: "2",
      image: blogImage2,
      points: t("blogs.blogPoints2"),
      title: t("blogs.blogtitle2"),
      desc: t("blogs.blogDesc2"),
    },
    {
      path: "/blog",
      id: "3",
      image: blogImage3,
      points: t("blogs.blogPoints3"),
      title: t("blogs.blogtitle3"),
      desc: t("blogs.blogDesc3"),
    },
    {
      path: "/blog",
      id: "4",
      image: blogImage4,
      points: t("blogs.blogPoints4"),
      title: t("blogs.blogtitle4"),
      desc: t("blogs.blogDesc4"),
    },
    {
      path: "/blog",
      id: "5",
      image: blogImage5,
      points: t("blogs.blogPoints5"),
      title: t("blogs.blogtitle5"),
      desc: t("blogs.blogDesc5"),
    },
    {
      path: "/blog",
      id: "6",
      image: blogImage6,
      points: t("blogs.blogPoints6"),
      title: t("blogs.blogtitle6"),
      desc: t("blogs.blogDesc6"),
    },
    {
      path: "/blog",
      id: "7",
      image: blogImage7,
      points: t("blogs.blogPoints7"),
      title: t("blogs.blogtitle7"),
      desc: t("blogs.blogDesc7"),
    },
    {
      path: "/blog",
      id: "8",
      image: blogImage8,
      points: t("blogs.blogPoints8"),
      title: t("blogs.blogtitle8"),
      desc: t("blogs.blogDesc8"),
    },
    {
      path: "/blog",
      id: "9",
      image: blogImage9,
      points: t("blogs.blogPoints9"),
      title: t("blogs.blogtitle9"),
      desc: t("blogs.blogDesc9"),
    },
    {
      path: "/blog",
      id: "10",
      image: blogImage10,
      points: t("blogs.blogPoints10"),
      title: t("blogs.blogtitle10"),
      desc: t("blogs.blogDesc10"),
    },
    {
      path: "/blog",
      id: "11",
      image: blogImage11,
      points: t("blogs.blogPoints11"),
      title: t("blogs.blogtitle11"),
      desc: t("blogs.blogDesc11"),
    },
    {
      path: "/blog",
      id: "12",
      image: blogImage12,
      points: t("blogs.blogPoints12"),
      title: t("blogs.blogtitle12"),
      desc: t("blogs.blogDesc12"),
    },
    {
      path: "/blog",
      id: "13",
      image: blogImage13,
      points: t("blogs.blogPoints13"),
      title: t("blogs.blogtitle13"),
      desc: t("blogs.blogDesc13"),
    },
    {
      path: "/blog",
      id: "14",
      image: blogImage14,
      points: t("blogs.blogPoints14"),
      title: t("blogs.blogtitle14"),
      desc: t("blogs.blogDesc14"),
    },
    {
      path: "/blog",
      id: "15",
      image: blogImage15,
      points: t("blogs.blogPoints15"),
      title: t("blogs.blogtitle15"),
      desc: t("blogs.blogDesc15"),
    },
    {
      path: "/blog",
      id: "16",
      image: blogImage16,
      points: t("blogs.blogPoints16"),
      title: t("blogs.blogtitle16"),
      desc: t("blogs.blogDesc16"),
    },
    {
      path: "/blog",
      id: "17",
      image: blogImage17,
      points: t("blogs.blogPoints17"),
      title: t("blogs.blogtitle17"),
      desc: t("blogs.blogDesc17"),
    },
    {
      path: "/blog",
      id: "18",
      image: blogImage18,
      points: t("blogs.blogPoints18"),
      title: t("blogs.blogtitle18"),
      desc: t("blogs.blogDesc18"),
    },
  ];
};
