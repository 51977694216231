import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

const TeamMember = React.memo(({ item }) => {
  function truncateText(text, wordLimit) {
    const words = text.split(" ");
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(" ") + "...";
  }

  return (
    <div className="rounded-xl shadow-md overflow-hidden h-full">
      <div className="relative h-full">
        <img
          src={item?.img}
          alt="member"
          className="h-full w-full object-cover"
          loading="lazy"
          role="presentation"
          decoding="async"
          fetchPriority="high"
        />
        <span className="absolute bottom-4 left-4 right-4 rounded-md bg-black/20 text-white text-center p-2 backdrop-blur-sm">
          <Tooltip
            title={
              <div>
                <p>{truncateText(item.description, 20)}</p>
              </div>
            }
          >
            <Link to={`/${item.path}/${item.id}`} key="details">
              {item?.name}
            </Link>
          </Tooltip>
        </span>
      </div>
    </div>
  );
});

export default TeamMember;
