import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const OurValue = React.memo(({ icon, title, desc, className, images }) => {
  return (
    <div
      className={`rounded-xl border border-border_color shadow-md p-4 ${className}`}
    >
      {images && (
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="rounded-xl overflow-hidden h-72 max-h-72 w-full">
                <img
                  src={image}
                  alt={`image_${index}`}
                  className="w-full h-full object-cover"
                  loading="lazy"
                  role="presentation"
                  decoding="async"
                  fetchPriority="high"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      <div className="flex flex-col gap-4">
        <span dangerouslySetInnerHTML={{ __html: icon }} />
        <div>
          <b>{title}</b> <span>{desc}</span>
        </div>
      </div>
    </div>
  );
});

export default OurValue;
