import { Button } from "antd";
import React from "react";
import { ArrowTopRight } from "../../utils/icons/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SectionCard = React.memo(({ item }) => {
  const { t } = useTranslation();

  return (
    <div className="rounded-xl shadow-md overflow-hidden h-96">
      <div className="relative h-full">
        <img
          src={item?.image}
          alt="member"
          className="h-full w-full object-cover"
          loading="lazy"
          role="presentation"
          decoding="async"
          fetchPriority="high"
        />

        <span className="absolute p-4 flex flex-col justify-between items-center bottom-0 left-0 right-0 top-0">
          <div className="flex gap-4 items-center justify-between w-full">
            <span className="rounded-md w-full bg-black/20 text-white text-center p-2 backdrop-blur-md">
              {t("titles.read_more")}
            </span>
            <Link to={`/${item?.path}/${item?.id}`}>
              <Button
                size="large"
                icon={
                  <span
                    dangerouslySetInnerHTML={{ __html: ArrowTopRight() }}
                  ></span>
                }
              />
            </Link>
          </div>
          <span className="rounded-md bg-black/30 text-white text-center p-2 backdrop-blur-sm">
            {item?.name}
          </span>
        </span>
      </div>
    </div>
  );
});

export default SectionCard;
