import React from "react";
import heroImage from "../assets/images/DSC00662.webp";
import HeroPage from "../components/HeroPage";
import { useTranslation } from "react-i18next";
import { coursesFunc } from "../utils/constants";
import Summary from "../components/Summary";

const AboutSaad = React.memo(() => {
  const { t } = useTranslation();

  const courses = coursesFunc(t);

  return (
    <div className="flex flex-col gap-14">
      <HeroPage
        title={t("heroImage.title2")}
        desc={t("heroImage.desc2")}
        heroImage={heroImage}
      />

      <div className="container flex flex-col gap-14">
        <div className="flex flex-col gap-6 justify-center">
          <h3 className="font-bold text-2xl lg:text-4xl text-center w-full">
            {t("header.about_us")}
          </h3>

          <p className="md:w-3/4 w-full mx-auto text-lg">
            <div
              dangerouslySetInnerHTML={{
                __html: t("titles.aboutUsTitle"),
              }}
            ></div>
          </p>
        </div>

        <div className="flex flex-col gap-8 justify-center">
          <div className="w-full flex justify-center">
            <span className="font-bold text-2xl lg:text-4xl text-center w-full">
              <div
                dangerouslySetInnerHTML={{
                  __html: t("titles.saadTraining"),
                }}
              ></div>
            </span>
          </div>

          {courses.map((course, index) => (
            <Summary
              btnType="default"
              btnURL={`/${course.path}/${course.id}`}
              title={course.name}
              btnTitle={t("titles.getNow")}
              Descriptions={course.description}
              imageSrc={course.img}
              reverse={index % 2 !== 0}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

export default AboutSaad;
