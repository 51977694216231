import React from "react";
import ConsultationForm from "../components/ConsultationForm";
import Summary from "../components/Summary";
import { coachingDataFunc, coursesFunc } from "../utils/constants";
import youthImage from "../assets/images/WhatsApp Image 2024-11-07 at 15.32.23_de273ebf.webp";
import Team from "../components/shared/Team";
import { useTranslation } from "react-i18next";

const ForYouth =React.memo( () => {
  const { t } = useTranslation();

  const coachingData = coachingDataFunc(t)
  const courses = coursesFunc(t)

  return (
    <div className="container flex flex-col gap-14 mt-8">
      <Summary
        title={
          <>
            {t('heroImage.ourTime_title')}
          </>
        }
        Descriptions={t('heroImage.ourTime_desc')}
        imageSrc={youthImage}
        hideBtn
      />
      <Team
        title={t('titles.psychiatrists')}
        data={coachingData.filter((item) => item.category === "psychiatrist")}
      />
      <Team
        title={t('titles.psychotherapists')}
        data={coachingData.filter((item) => item.category === "psychologist")}
      />
      <Team
        title={t('titles.recoveryCoaching')}
        data={coachingData.filter((item) => item.category === "recovery")}
      />
      <Team
        title={t('titles.fitnessAndTrainers')}
        data={coachingData.filter((item) => item.category === "fitness")}
      />
      <Team
        title={t('titles.familyCoach')}
        data={coachingData.filter((item) => item.category === "familyCoach")}
      />
      <Team
        title={t('titles.logisticsAndManagement')}
        data={coachingData.filter((item) => item.category === "management")}
      />
      <Team title={t("titles.books")} data={courses} />

      {/* <Summary
        title={
          <>
            Intensive programme{" "}
            <span className="text-highLight_color">10 days</span>
          </>
        }
        Descriptions={
          <span>
            This 10-day program offers a comprehensive, personalized approach to
            understanding and overcoming addiction. The program includes
            sessions with doctors, experts, and specialists to create a
            detailed, tailored recovery plan that fits your specific needs.
          </span>
        }
        imageSrc={daysImage}
        btnTitle="Get Now"
      /> */}

      <ConsultationForm />
    </div>
  );
});

export default ForYouth;
