import React from "react";
import HeroPage from "../components/HeroPage";
import Summary from "../components/Summary";
import TestimonialsItem from "../components/eliteWellbeing/TestimonialsItem";
import CommentsSection from "../components/shared/CommentsSection";
import summaryImage from "../assets/images/We Offer Elite WellbeingTo Help You And Your Family.webp";
import { useTranslation } from "react-i18next";
// import { motion } from "framer-motion";
import {
  coursesFunc,
  coachingDataFunc,
  programsFunc,
  commentsEliteWellbeingData,
} from "../utils/constants";
import heroImage from "../assets/images/WhatsApp_Image_2024-11-27_at_23.58.30_c22ba8e9-removebg-preview.webp";
import Team from "../components/shared/Team";
import Programs from "../components/Programs";

// const { Meta } = Card;

// const animationRightToLeft = {
//   hidden: { opacity: 0, x: 50 },
//   visible: (index) => ({
//     opacity: 1,
//     x: 0,
//     transition: {
//       delay: index * 0.2,
//       duration: 0.5,
//     },
//   }),
// };

const Home = React.memo(() => {
  const { t } = useTranslation();

  const programs = programsFunc(t);

  const courses = coursesFunc(t);

  const coachingData = coachingDataFunc(t);

  return (
    <div className="flex flex-col gap-14">
      <HeroPage
        title={t("heroImage.title")}
        desc={t("heroImage.desc")}
        btnTitle={t("header.contact_now")}
        ratingData={[
          { value: "+3000", name: t("titles.well_being") },
          { value: "78%", name: t("titles.success_rate") },
          { value: "12M", name: t("titles.scope_of_impact") },
        ]}
        heroImage={heroImage}
        btnPath="https://wa.me/+905551675716"
      />
      <div className="flex flex-col gap-14">
        <div className="container mx-auto ">
          <Summary
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: t("heroImage.title3"),
                }}
              ></div>
            }
            Descriptions={t("heroImage.desc3")}
            isVideo
            btnTitle={t("titles.read_more")}
            btnURL="/our_story"
          />
        </div>

        <div className="container mx-auto ">
          <Team
            data={coachingData}
            title={t("header.our_team")}
            path="/our-team"
            inHome
          />
        </div>

        <div className="container mx-auto ">
          <Programs
            data={programs}
            title={t("header.our_programs")}
            path="/program"
          />
        </div>

        <div className="container mx-auto ">
          <Summary
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: t("titles.elite_wellbeing_title_home"),
                }}
              ></div>
            }
            btnTitle={t("titles.askHelp")}
            Descriptions={t("titles.elite_wellbeing_desc_home")}
            imageSrc={summaryImage}
            btnURL="https://wa.me/+905551675716"
            reverse
          />
        </div>

        <div className="flex flex-col gap-4 sm:hidden">
          <h3 className="text-xl text-center font-bold uppercase">
            {t("titles.testimonials")}
          </h3>
          <p className="text-center">{t("titles.testimonialsTitle")}</p>
          {commentsEliteWellbeingData.map((item, index) => (
            <TestimonialsItem key={index} item={item} inHome />
          ))}
        </div>

        <div className="hidden flex-col gap-4 bg-bg_lightGray sm:flex">
          <CommentsSection data={commentsEliteWellbeingData} />
        </div>

        <div className="container mx-auto ">
          <Team data={courses} title={t("titles.books")} path="/our_story" />
        </div>
      </div>
    </div>
  );
});

export default Home;
