import React from "react";
import { Rate } from "antd";

const CommentSuccessStory = React.memo(({ comment, reverse }) => {
  return (
    <div
      className={`w-full flex items-center gap-8 ${
        reverse ? "md:flex-row-reverse" : "md:flex-row"
      } flex-col-reverse`}
    >
      <div className="overflow-hidden w-full md:w-1/2">
        <div className="relative">
          <img
            src={comment?.image}
            alt="member"
            className="h-full w-full object-cover"
            loading="lazy"
            role="presentation"
            decoding="async"
            fetchPriority="high"
          />
          <span className="absolute bottom-4 left-4 right-4 border border-border_color bg-black/10 text-white text-center p-2 backdrop-blur-sm flex flex-col gap-2">
            <b className="text-lg">{comment.member}</b>
            <small>{comment.age} years old</small>
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-4 w-full md:w-1/2 h-full justify-between">
        <Rate disabled defaultValue={comment.rate} />
        <span>{comment?.content}</span>
      </div>
    </div>
  );
});

export default CommentSuccessStory;
