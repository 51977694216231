import React from "react";
import logo1 from "../../assets/images/3add11022ff5d86f3ec7335ddbda3ec3.webp";
import logo2 from "../../assets/images/1907b6979bd58f782ac1bf1268a9d823.webp";
import logo3 from "../../assets/images/WhatsApp Image 2024-11-02 at 00.52.30_96363c24.webp";

const RestartPartners =React.memo( () => {
  return (
    <div className="flex md:flex-row flex-col justify-between items-center gap-4 px-0 md:px-8">
      <h3 className="font-bold text-base md:text-xl lg:text-3xl">Restart Partners</h3>
      <div className="flex items-center gap-8">
        <div className="w-auto h-10">
          <img
            className="w-full h-full object-cover"
            src={logo3}
            alt="logo_3"
            loading="lazy"
            role="presentation"
            decoding="async"
            fetchPriority="high"
          />
        </div>
        <div className="w-auto h-6 md:h-10">
          <img
            className="w-full h-full object-cover"
            src={logo1}
            alt="logo_1"
            loading="lazy"
            role="presentation"
            decoding="async"
            fetchPriority="high"
          />
        </div>
        <div className="w-auto h-6 md:h-10">
          <img
            className="w-full h-full object-cover"
            src={logo2}
            alt="logo_2"
            loading="lazy"
            role="presentation"
            decoding="async"
            fetchPriority="high"
          />
        </div>
      </div>
    </div>
  );
});

export default RestartPartners;
