import React from "react";
import { blogsFunc } from "../utils/constants";
import Blog from "../components/Blog";
import { useTranslation } from "react-i18next";

const Blogs =React.memo( () => {
  const { t } = useTranslation();

  const blogs = blogsFunc(t)

  return (
    <div className="container grid gap-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8">
      {blogs.map((blog, index) => (
        <Blog key={index} blog={blog} />
      ))}
    </div>
  );
});

export default Blogs;
