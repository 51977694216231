import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Button } from "antd";
import { programsFunc } from "../utils/constants";
import { useTranslation } from "react-i18next";

const ProgramDetails = React.memo(() => {
  const { t } = useTranslation();

  const { id } = useParams();

  const [item, setItem] = useState(null);

  useEffect(() => {
    const itemData = programs.find((item) => item.id === id);
    setItem(itemData);
    window.scrollTo(0, 0);
  }, [id]);

  const programs = programsFunc(t);

  return (
    <div className="container">
      <div className="w-full flex items-start gap-8 flex-col md:flex-row">
        <div className="rounded-xl overflow-hidden w-full md:w-1/2 h-screen">
          <img
            src={item?.image}
            alt="section_image"
            className="w-full h-full object-cover"
            loading="lazy"
            role="presentation"
            decoding="async"
            fetchPriority="high"
          />
        </div>
        <div className="flex flex-col gap-4 w-full md:w-1/2 h-full justify-between">
          {item?.name && (
            <h3 className="font-bold text-xl lg:text-3xl text-textGray">
              {item?.name}
            </h3>
          )}

          {item?.title && (
            <span className="text-highLight_color text-2xl lg:text-5xl font-bold">
              {item?.title}
            </span>
          )}

          {item?.programData?.description && (
            <span>{item?.programData?.description}</span>
          )}

          {item?.programData?.offerings?.length ? (
            <div className="flex flex-col gap-4">
              <b className="text-base lg:text-xl">
                {t("titles.offerProgram")}:{" "}
              </b>
              <div className="flex flex-col gap-2">
                {item?.programData.offerings.map((offer, index) => (
                  <li key={index} className="text-textGray">
                    {offer?.title}
                  </li>
                ))}
              </div>
            </div>
          ) : null}

          {item?.programData?.keyDeliverables?.length ? (
            <div className="flex flex-col gap-4">
              <b className="text-base lg:text-xl">
                {t("titles.deliverablesProgram")}:{" "}
              </b>
              <div className="flex flex-col gap-2">
                {item?.programData.keyDeliverables.map((key, index) => (
                  <div className="flex flex-col gap-2" key={index}>
                    <span>{key?.title} : </span>
                    <span className="ml-2 text-textGray">{key.details}</span>
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          <div className="flex flex-col gap-8">
            {item?.programData?.subPrograms &&
              item?.programData?.subPrograms.map((program, index) => (
                <div
                  className="flex flex-col gap-4 border border-borderLight_color rounded-lg p-4"
                  key={index}
                >
                  <h3 className="font-bold text-2xl text-highLight_color">
                    {program?.name}
                  </h3>
                  <span className="text-textGray">{program?.description}</span>
                  {program?.offerings.length > 0 ? (
                    <Link to={`/${program.path}/${item.id}/${program.id}`}>
                      <Button type="primary">{t("titles.read_more")}</Button>
                    </Link>
                  ) : (
                    <Link to="https://wa.me/+905551675716">
                      <Button type="primary">{t("titles.getProgram")}</Button>
                    </Link>
                  )}
                </div>
              ))}
          </div>

          {!item?.programData?.subPrograms.length && (
            <a href={item?.link} target="_blank" rel="noreferrer">
              <Button type="primary" className="w-full">
                {t("titles.getProgram")}
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
});

export default ProgramDetails;
